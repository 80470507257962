<router-outlet> </router-outlet>
<ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#64d6e2" fullScreen=true
    type="ball-clip-rotate-multiple">
</ngx-spinner> 



<div [className]="checkpopup ? 'modal fade in' : 'modal fade'" [style.display]="showModal ? 'block' : 'none'"
    style="opacity: 1;">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content" style="    margin-top: 80px;">
            <!-- <div class="modal-header"> -->
                <!-- <h5 class="modal-title" id="exampleModalLabel">Change Password</h5> -->
            <!-- </div> -->
            <div class="modal-body custom-modal-body-Aj">
                <div class="container-fluid">
                    <form [formGroup]="passwordForm" (ngSubmit)="onSubmit()" class="form-horizontal agent-match">
                        <div class="agent-Text">Change Password</div>
                        <div class="p-3">
                            <div class="form-group">
                                <label class="control-label col-sm-6" for="email">Old Password:</label>
                                <div class="col-sm-12">
                                    <input class="form-control" placeholder="OLD PASSWORD" formControlName="oldPassword"
                                        type="password">
                                    <div *ngIf="submitted && f.oldPassword.errors" class="col-lg-12 col-sm-offset-2">
                                        <div class="validation" *ngIf="f.oldPassword.errors.required">Old Password is required</div>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group">
                                <label class="control-label col-sm-6" for="pwd">New Password:</label>
                                <div class="col-sm-12">
                                    <input class="form-control" placeholder="NEW PASSWORD" formControlName="newPassword"
                                        type="password">
                                    <div *ngIf="submitted && f.newPassword.errors" class="col-lg-12 col-sm-offset-2">
                                        <div class="validation" *ngIf="f.newPassword.errors.required">New Password is required</div>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group">
                                <label class="control-label col-sm-6" for="pwd">Confirm Password:</label>
                                <div class="col-sm-12">
                                    <input class="form-control" placeholder="CONFIRM PASSWORD" formControlName="confirmPassword"
                                        type="password">
                                    <div *ngIf="submitted && f.confirmPassword.errors" class="col-lg-12 col-sm-offset-2">
                                        <div class="validation" *ngIf="f.confirmPassword.errors.required">Confirm Password is
                                            required</div>
                                        <div class="validation" *ngIf="f.confirmPassword.errors.mustMatch">Passwords must match
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group">
                                <div class="col-sm-offset-2 col-sm-12">
                                    <button [disabled]="submitted && passwordForm.invalid" class="btn btn-info">Save
                                        Changes</button>
                                        <button class="btn btn-info" style="    float: right;
                                        background: red;
                                        border: red;" (click)="logout()">Logout</button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>
