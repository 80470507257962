<!-- Main Sidebar Container -->
<aside class="main-sidebar sidebar-dark-primary elevation-4"style="background: #6F8FCA" >
    <!-- Brand Logo -->
    <a routerLink="/" class="brand-link" style="background: #3a61a2;    color: #fff;">
        <img src="http://aking365.com/assets/images/heroic_logo.png" alt="AdminLTE Logo" class="brand-image img-circle elevation-3"
            style="opacity: .8">
        <span class="brand-text font-weight-light">GURU7777</span>
    </a>

    <!-- Sidebar -->
    <div class="sidebar" >
        <!-- Sidebar user panel (optional) -->
        <div class="user-panel mt-3 pb-3 mb-3 d-flex">
            <div class="image">
                <!-- <img src="assets/dist/img/user2-160x160.jpg" class="img-circle elevation-2" alt="User Image"> -->
            </div>
            <div class="info">
                <!-- <a class="d-block">{{ name.split(' ')[0] || '-' }}</a> -->
                <a class="d-block">{{ name }}</a>
            </div>
        </div>

        <!-- Sidebar Menu -->
        <nav class="mt-2">
            <ul class="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false">
                <!-- Add icons to the links using the .nav-icon class
             with font-awesome or any other icon font library -->
                <li class="nav-item">
                    <a routerLink="/" class="nav-link">
                        <i class="nav-icon fas fa-th"></i>
                        <p>
                            Dashboard
                        </p>
                    </a>
                </li>
                <li *ngIf="level == '1'" class="nav-item">
                    <a class="nav-link" data-toggle="modal" data-target="#amountModal" data-backdrop="static"
                        data-keyboard="false">
                        <i class="nav-icon fas fa-rupee-sign"></i>
                        <p>
                            Add Balance
                        </p>
                    </a>
                </li>
                <li *ngIf="level > '0' && level < '7'" class="nav-item">
                    <a class="nav-link">
                        <i class="nav-icon fas fa-users"></i>
                        <p>
                            Manage
                            <i class="fas fa-angle-left right"></i>
                        </p>
                    </a>
                    <ul class="nav nav-treeview">
                        <li *ngIf="level == '1'" class="nav-item">
                            <a routerLink="/user/list/2" class="nav-link">
                                <i class="fas fa-user nav-icon"></i>
                                <p>SM</p>
                            </a>
                        </li>
                        <li *ngIf="level <= '2'" class="nav-item">
                            <a routerLink="/user/list/3" class="nav-link">
                                <i class="fas fa-user nav-icon"></i>
                                <p>MT</p>
                            </a>
                        </li>
                        <li *ngIf="level <= '3'" class="nav-item">
                            <a routerLink="/user/list/4" class="nav-link">
                                <i class="fas fa-user nav-icon"></i>
                                <p>SC</p>
                            </a>
                        </li>
                        <li *ngIf="level <= '4'" class="nav-item">
                            <a routerLink="/user/list/5" class="nav-link">
                                <i class="fas fa-user nav-icon"></i>
                                <p>SST</p>
                            </a>
                        </li>
                        <li *ngIf="level <= '5'" class="nav-item">
                            <a routerLink="/user/list/6" class="nav-link">
                                <i class="fas fa-user nav-icon"></i>
                                <p>SS</p>
                            </a>
                        </li>
                        <li *ngIf="level <= '6'" class="nav-item">
                            <a routerLink="/user/list/7" class="nav-link">
                                <i class="fas fa-user nav-icon"></i>
                                <p>SA</p>
                            </a>
                        </li>
                    </ul>
                </li>
                <li *ngIf="level > '0'" class="nav-item">
                    <a class="nav-link">
                        <i class="nav-icon fas fa-golf-ball"></i>
                        <p>
                            Sports
                            <i class="fas fa-angle-left right"></i>
                        </p>
                    </a>
                    <ul class="nav nav-treeview">
                        <li class="nav-item">
                            <a routerLink="/betting" class="nav-link">
                                <i class="far fa-circle nav-icon"></i>
                                <p>Matches</p>
                            </a>
                        </li>
                        <li class="nav-item">
                            <a routerLink="/casino" class="nav-link">
                                <i class="far fa-circle nav-icon"></i>
                                <p>Casino</p>
                            </a>
                        </li>
                        <!-- <li class="nav-item">
                            <a routerLink="/jantri" class="nav-link">
                                <i class="far fa-circle nav-icon"></i>
                                <p>Jantri</p>
                            </a>
                        </li> -->
                    </ul>
                </li>
                <li *ngIf="level > '0' && level <= '7'" class="nav-item">
                    <a class="nav-link">
                        <i class="nav-icon fas fa-users"></i>
                        <p>
                            Manage Clients
                            <i class="fas fa-angle-left right"></i>
                        </p>
                    </a>
                    <ul class="nav nav-treeview">
                        <li class="nav-item">
                            <a routerLink="/user/list/8" class="nav-link">
                                <i class="fas fa-users nav-icon"></i>
                                <p>My Client</p>
                            </a>
                        </li>
                        <li class="nav-item">
                            <a routerLink="/user/inactiveUsers" class="nav-link">
                                <i class="fas fa-user-times nav-icon"></i>
                                <p>Blocked Clients</p>
                            </a>
                        </li>
                        <li class="nav-item">
                            <a routerLink="/user/commAndLimit" class="nav-link">
                                <i class="fas fa-rupee-sign nav-icon"></i>
                                <p>Commission & Limits</p>
                            </a>
                        </li>
                    </ul>
                </li>
                <li *ngIf="level > '0'" class="nav-item">
                    <a class="nav-link">
                        <i class="nav-icon fas fa-chart-pie"></i>
                        <p>
                            Ledgers
                            <i class="fas fa-angle-left right"></i>
                        </p>
                    </a>
                    <ul class="nav nav-treeview">
                        <li class="nav-item">
                            <a routerLink="/ledger/collection-report" class="nav-link">
                                <i class="far fa-circle nav-icon"></i>
                                <p>Collection Report</p>
                            </a>
                        </li>
                        <li *ngIf="level < 8" class="nav-item">
                            <a routerLink="/ledger/myLedger" class="nav-link">
                                <i class="far fa-circle nav-icon"></i>
                                <p>My Ledgers</p>
                            </a>
                        </li>
                        <li class="nav-item">
                            <a routerLink="/ledger/profit-loss" class="nav-link">
                                <i class="far fa-circle nav-icon"></i>
                                <p>Profit & Loss</p>
                            </a>
                        </li>
                        <li *ngIf="level < 8" class="nav-item">
                            <a routerLink="/betting/commission-report" class="nav-link">
                                <i class="far fa-circle nav-icon"></i>
                                <p>Commission Report</p>
                            </a>
                        </li>
                        <li *ngIf="level < 8" class="nav-item">
                            <a routerLink="/user/user-coin-history" class="nav-link">
                                <i class="far fa-circle nav-icon"></i>
                                <p>User Coin History</p>
                            </a>
                        </li>
                        <li *ngIf="level < 8" class="nav-item">
                            <a routerLink="/user/my-downline" class="nav-link">
                                <i class="far fa-circle nav-icon"></i>
                                <p>My Downline</p>
                            </a>
                        </li>

                    </ul>
                </li>
                <li *ngIf="level > '0'" class="nav-item">
                    <a routerLink="/user/manage-password" class="nav-link">
                        <i class="nav-icon fas fa-lock"></i>
                        <p>
                            Manage Password
                        </p>
                    </a>
                </li>
                <li *ngIf="level == '1' || level == '0'" class="nav-item">
                    <a class="nav-link">
                        <i class="nav-icon fas fa-cog"></i>
                        <p>
                            Settings
                            <i class="fas fa-angle-left right"></i>
                        </p>
                    </a>
                    <ul class="nav nav-treeview">
                        <li class="nav-item">
                            <a routerLink="/match/addMatch" class="nav-link">
                                <i class="far fa-circle nav-icon"></i>
                                <p>Match Add</p>
                            </a>
                        </li>
                        <li *ngIf="level == '0'" class="nav-item">
                            <a routerLink="/match/activeMatch" class="nav-link">
                                <i class="far fa-circle nav-icon"></i>
                                <p>Active Match</p>
                            </a>
                        </li>
                        <li *ngIf="level == '0'" class="nav-item">
                            <a routerLink="/match/inactiveMatch" class="nav-link">
                                <i class="far fa-circle nav-icon"></i>
                                <p>Inactive Match</p>
                            </a>
                        </li>
                        <li *ngIf="level == '1'" class="nav-item">
                            <a routerLink="/bsfSetting/message" class="nav-link">
                                <i class="far fa-circle nav-icon"></i>
                                <p>Message</p>
                            </a>
                        </li>
                        <li class="nav-item">
                            <a routerLink="/result" class="nav-link">
                                <i class="far fa-circle nav-icon"></i>
                                <p>Result</p>
                            </a>
                        </li>
                        <li *ngIf="level == '0'" class="nav-item">
                            <a routerLink="/suspended" class="nav-link">
                                <i class="far fa-circle nav-icon"></i>
                                <p>Suspended Result</p>
                            </a>
                        </li>
                        <li class="nav-item">
                            <a routerLink="/match" class="nav-link">
                                <i class="far fa-circle nav-icon"></i>
                                <p>Sport Setting</p>
                            </a>
                        </li>
                    </ul>
                </li>
                <li *ngIf="level == '1'" class="nav-item">
                    <a class="nav-link">
                        <i class="nav-icon fas fa-cog"></i>
                        <p>
                            Website Settings
                            <i class="fas fa-angle-left right"></i>
                        </p>
                    </a>
                    <ul class="nav nav-treeview">
                        <li class="nav-item">
                            <a routerLink="/bsfSetting" class="nav-link">
                                <i class="far fa-circle nav-icon"></i>
                                <p>Setting</p>
                            </a>
                        </li>
                    </ul>
                </li>
            </ul>
        </nav>
        <!-- /.sidebar-menu -->
    </div>
    <!-- /.sidebar -->
</aside>

<section class="marquee-section">
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-12">
                <ul class="marquee">
                    <marquee>
                        <li id="marqmessage">{{ message }}</li>
                    </marquee>
                </ul>
            </div>
        </div>
    </div>
</section>

<div class="modal fade" id="amountModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
    aria-hidden="true">
    <div class="modal-dialog" role="document" style="max-width: 350px;">
        <div class="modal-content">
            <div class="modal-header">
                <button id="close" type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body" style="text-align: center;">
                <i class="nav-icon icon fas fa-rupee-sign"></i>
                <h4><strong>My Balance: {{ user?.coins }}</strong></h4>
                <input #error="ngModel" [pattern]="pattern" class="form-control" [(ngModel)]="coins"
                    placeholder="Enter Amount" type="text">
                <span style="color:red;" *ngIf="error.errors?.pattern">
                    Numeric Value Only
                </span>
                <br>
                <button [disabled]="error.errors?.pattern || error.value==''" (click)="depositSelfCoins()"
                    class="btn btn-primary" type="button">
                    Add Amount
                </button>
            </div>
        </div>
    </div>
</div>