import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';
import { AuthLayoutComponent } from './layout/auth-layout/auth-layout.component';
import { ContentLayoutComponent } from './layout/content-layout/content-layout.component';
import { AuthGuard } from './core/guards/auth.guard';
import { WelcomeComponent } from './module/welcome/welcome.component';
import { ManagePasswordComponent } from './module/user/page/manage-password/manage-password.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: '/dashboard',
    pathMatch: 'full'
  },
  // {
  //   path: 'manage-password',
  //   component: ManagePasswordComponent,
  // },
  {
    path: '',
    component: ContentLayoutComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'dashboard',
        loadChildren: () =>
          import('./module/dashboard/dashboard.module').then(m => m.DashboardModule)
      },
      {
        path: 'user',
        loadChildren: () =>
          import('./module/user/user.module').then(m => m.UserModule)
      },
      {
        path: 'match',
        loadChildren: () =>
          import('./module/sport-setting/sport-setting.module').then(m => m.SportSettingModule)
      },
      {
        path: 'betting',
        loadChildren: () =>
          import('./module/sport-betting/sport-betting.module').then(m => m.SportBettingModule)
      },
      {
        path: 'result',
        loadChildren: () =>
          import('./module/result/result.module').then(m => m.ResultModule)
      },
      {
        path: 'suspended',
        loadChildren: () =>
          import('./module/suspended-result/suspended-result.module').then(m => m.SuspendedResultModule)
      },
      {
        path: 'ledger',
        loadChildren: () =>
          import('./module/ledger/ledger.module').then(m => m.LedgerModule)
      },
      {
        path: 'bsfSetting',
        loadChildren: () =>
          import('./module/bsf-setting/bsf-setting.module').then(m => m.BsfSettingModule)
      },
      {
        path: 'casino',
        loadChildren: () =>
          import('./module/casino/casino.module').then(m => m.CasinoModule)
      },
      // {
      //   path: 'jantri',
      //   loadChildren: () =>
      //     import('./module/jantri/jantri.module').then(m => m.JantriModule)
      // }
    ]
  },
  {
    path: 'auth',
    component: AuthLayoutComponent,
    loadChildren: () =>
      import('./module/auth/auth.module').then(m => m.AuthModule)
  },
  {
    path: 'welcome',
    component: WelcomeComponent,
  },
  // Fallback when no prior routes is matched
  { path: '**', redirectTo: '/dashboard', pathMatch: 'full' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules, relativeLinkResolution: 'legacy' })],
  exports: [RouterModule],
  providers: [AuthGuard]
})
export class AppRoutingModule { }
