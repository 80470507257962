import { Component } from '@angular/core';
import { Subscription } from 'rxjs';
import { AuthService } from './service/auth.service';
import { SocketService } from './service/socket.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UserService } from 'src/app/service/user.service';
import swal from "sweetalert2";
import { NgxSpinnerService } from "ngx-spinner";
declare var $: any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'trophyAdmin';
  isLogin = false;
  level: string;
  userId = '';
  private authListenerSubs: Subscription;
  showModal: boolean = false;
  checkpopup: boolean = false;

  passwordForm: FormGroup;
  submitted = false;
  userName: any;
  private pSubs: Subscription;
  constructor(private authService: AuthService, private socketService: SocketService, private formBuilder: FormBuilder, private userService: UserService, private spinner: NgxSpinnerService) { }

  ngOnInit() {

    if (localStorage.getItem('AjA') == '1') {
      this.showpopup();
    }

    this.userName = this.authService.getUserName();
    this.passwordForm = this.formBuilder.group({
      oldPassword: ['', [Validators.required]],
      newPassword: ['', [Validators.required]],
      confirmPassword: ['', Validators.required]
    }, {
      validator: this.MustMatch('newPassword', 'confirmPassword')
    });
    this.authService.autoAuthUser();
    this.isLogin = this.authService.getIsAuth();
    if (this.isLogin) {
      this.socketService.connect();
    }
    this.authListenerSubs = this.authService
      .getAuthStatusListner()
      .subscribe((IsAuthenticated) => {
        this.isLogin = IsAuthenticated;
        if (this.isLogin) {
          this.socketService.connect();
          setTimeout(() => {
            location.reload();
          }, 50);

          // this.logoutSubs = this.socketService.getUpdateLUserListner().subscribe(data => {
          // console.log(data.userL);
          // data.userL.status && this.authService.dlogout();
          // });
        }
        else {
          //this.logoutSubs && this.logoutSubs.unsubscribe();
          this.socketService.disconnect();
        }
      });
  }

  ngAfterViewInit(): void {
    //Called after ngAfterContentInit when the component's view has been initialized. Applies to components only.
    //Add 'implements AfterViewInit' to the class.
    $('#passwordcheck').modal({
      backdrop: 'static',
      keyboard: false
    });
  }

  showpopup() {
    this.showModal = true;
    this.checkpopup = true;
    // console.log(this.showModal);
  }

  hidepopup() {
    this.showModal = false;
    this.checkpopup = false;
  }


  MustMatch(controlName: string, matchingControlName: string) {
    return (formGroup: FormGroup) => {
      const control = formGroup.controls[controlName];
      const matchingControl = formGroup.controls[matchingControlName];
      if (matchingControl.errors && !matchingControl.errors.mustMatch) {
        return;
      }
      if (control.value !== matchingControl.value) {
        matchingControl.setErrors({ mustMatch: true });
      } else {
        matchingControl.setErrors(null);
      }
    }
  }

  get f() { return this.passwordForm.controls; }

  onSubmit() {
    this.submitted = true;
    this.spinner.show();
    if (this.passwordForm.invalid) {
      this.spinner.hide();
      return;
    }

 
    this.pSubs = this.userService.changeOPassword(this.f.oldPassword.value, this.f.newPassword.value, this.f.confirmPassword.value).subscribe((data:any) => {
      //console.log(data);
      this.hidepopup();
      this.spinner.hide();
      this.msgPopup(data['message'], data['status']);
      this.onReset()
      if(data.status == 'success'){
       this.logout()
      }else{
        this.showpopup();
      }
    }, error => {
      //console.log(error);
      this.showpopup();
      this.spinner.hide();
      this.msgPopup(error.error['message'], 'error');
      this.submitted = false;
    });
  }

  logout(){
    this.hidepopup()
    this.authService.logout();
  }

  onReset() {
    this.submitted = false;
    this.passwordForm.reset();
  }

  msgPopup(title, icon) {
    swal.fire({
      icon: icon,
      title: title,
      showConfirmButton: false,
      timer: 1500
    })
  }


  ngOnDestroy() {
    this.authListenerSubs.unsubscribe();
    this.pSubs && this.pSubs.unsubscribe();
  }
}
