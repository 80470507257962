import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AuthService } from '../../service/auth.service';

@Injectable()
export class AuthGuard implements CanActivate {
    constructor(private authService: AuthService, private router: Router) { }

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): boolean | Observable<boolean> | Promise<boolean> {
        let isAuth = this.authService.getIsAuth();
        let newuser =localStorage.getItem("AjA");
        // console.log(typeof newuser,'newusernewuser');

        // if(newuser == '1'){
        //     console.log('ajay', newuser);
            
        //     // this.router.navigate(['/user/manage-password']);
        //     return isAuth;
        // }
        
        const token = localStorage.getItem("tokenA");
        
        if (token == null || token == 'null') {
            isAuth = false;
        }
        
        if (!isAuth) {
            this.router.navigate(['/auth/login']);
        }
        return isAuth;
    }
}