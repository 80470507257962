import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { Subject, forkJoin } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';

const BACKEND_URL = environment.apiUrl + '/user';

@Injectable({ providedIn: "root" })
export class UserService {
    private user: any;
    private userD: any;
    private userId: any;
    private users: any[] = [];
    private clients: any[] = [];
    private otp: any[] = [];
    private lenden: any[] = [];
    private lendenE: any[] = [];
    private usersD: any[] = [];
    private usersDL: any[] = [];
    private message: any = {};
    private setting: any = {};
    private dLReport: any = [];
    private dLJReport: any = [];
    private cHistory: any = [];
    private inActiveUsers: any[] = [];
    private usersA: any[] = [];
    private usersDAC: any[] = [];
    private userUpdated = new Subject<{ any }>();
    private userDUpdated = new Subject<{ any }>();
    private userIdUpdated = new Subject<{ any }>();
    private usersUpdated = new Subject<{ users: any[], totalCount: number }>();
    private clientsUpdated = new Subject<{ clients: any[], totalCount: number }>();
    private otpUpdated = new Subject<{ otp: any[] }>();
    private lendenEUpdated = new Subject<{ lendenE: any[] }>();
    private lendenUpdated = new Subject<{ lenden: any[] }>();
    private usersDUpdated = new Subject<{ usersD: any[] }>();
    private messageUpdated = new Subject<{ message: any }>();
    private settingUpdated = new Subject<{ setting: any }>();
    private usersDLUpdated = new Subject<{ usersDL: any[], current: any[], fixLimit: number }>();
    private dLReportUpdated = new Subject<{ dLReport: any[] }>();
    private dLJReportUpdated = new Subject<{ dLJReport: any[] }>();
    private cHUpdated = new Subject<{ cHistory: any[] }>();
    private inActiveUsersUpdated = new Subject<{ inActiveUsers: any[], totalCount: number }>();
    private usersAUpdated = new Subject<{ usersA: any[] }>();
    private usersDACUpdated = new Subject<{ usersDAC: any[] }>();
    constructor(private http: HttpClient) { }

    createUser(userId: string, firstName: string, lastName: string, fixLimit: any,
        myShare: Number, agentShare: Number, mComm: any, sComm: any, slComm: any, cComm: any, jComm: any,
        jBhav: any, hBhav: any, jDuration: any, level: any, password: string,
        cPassword: string) {
        // console.log('call');
        // return
        const userData = {
            userId, firstName, lastName, fixLimit, myShare, agentShare, mComm, sComm, slComm, cComm, jComm, jBhav, hBhav, jDuration, level, password, cPassword
        };

        return this.http.post(BACKEND_URL + "/createUser", userData);
    }

    updateUser(id: any, name: string, fixLimit: any,
        myShare: any, agentShare: any, mComm: any, sComm: any, slComm: any, cComm: any,
        jComm: any, jBhav: any, hBhav: any, jDuration: any, status: Boolean, betStatus: Boolean) {
        const userData = {
            name, fixLimit, myShare, agentShare, mComm, sComm, slComm, cComm, jComm, jBhav, hBhav,
            jDuration, status, betStatus
        };
        //console.log(userData);
        return this.http.put(BACKEND_URL + "/updateUser/" + id, userData);
    }

    getUserById() {
        this.http.post<{ message: string, result: any, status: number }>(BACKEND_URL + "/getUserById", {})
            .subscribe((userData) => {
                this.user = userData;
                this.userUpdated.next(this.user.result);
            });
    }

    getUpdateUserListner() {
        return this.userUpdated.asObservable();
    }

    getDUserById(id) {
        this.http.post<{ message: string, result: any, status: number }>(BACKEND_URL + "/getDUserById", { id })
            .subscribe((userData) => {
                this.userD = userData;
                this.userDUpdated.next(this.userD.result);
            });
    }

    getUpdateDUserListner() {
        return this.userDUpdated.asObservable();
    }

    getUserByUID(userName) {
        this.http.post<{ message: string, result: any, status: number }>(BACKEND_URL + "/getUserByUID", { userName })
            .subscribe((userData) => {
                this.userId = userData;
                this.userIdUpdated.next(this.userId.result);
            });
    }

    getUpdateUIdListner() {
        return this.userIdUpdated.asObservable();
    }

    getUsers(limit: number, currentPage: number, level: string, search: string) {
        const queryParams = `?pagesize=${limit}&page=${currentPage}&level=${level}&search=${search}`;
        this.http.post<{ message: string, result: any, maxUsers: number }>(BACKEND_URL + "/getUsers" + queryParams, {})
            .pipe(map((userData) => {
                return {
                    users: userData.result.map(user => {
                        return {
                            name: user.name,
                            firstName: user.firstName,
                            lastName: user.lastName,
                            userName: user.userName,
                            id: user._id,
                            password: user.password,
                            pMatchShare: user.top.level == '1' ? user.matchShare[0] : user.top.level == '2' ? user.matchShare[1] : user.top.level == '3' ? user.matchShare[2] : user.top.level == '4' ? user.matchShare[3] : user.top.level == '5' ? user.matchShare[4] : user.top.level == '6' ? user.matchShare[5] : user.matchShare[6],
                            matchShare: user.level == 2 ? user.matchShare[1] : user.level == 3 ? user.matchShare[2] : user.level == 4 ? user.matchShare[3] : user.level == 5 ? user.matchShare[4] : user.level == 6 ? user.matchShare[5] : user.level == 7 ? user.matchShare[6] : user.matchShare[6],
                            mComm: user.level == 2 ? user.mComm[1] : user.level == 3 ? user.mComm[2] : user.level == 4 ? user.mComm[3] : user.level == 5 ? user.mComm[4] : user.level == 6 ? user.mComm[5] : user.level == 7 ? user.mComm[6] : user.mComm[6],
                            sComm: user.level == 2 ? user.sComm[1] : user.level == 3 ? user.sComm[2] : user.level == 4 ? user.sComm[3] : user.level == 5 ? user.sComm[4] : user.level == 6 ? user.sComm[5] : user.level == 7 ? user.sComm[6] : user.sComm[6],
                            slComm: user.level == 2 ? user.slComm[1] : user.level == 3 ? user.slComm[2] : user.level == 4 ? user.slComm[3] : user.level == 5 ? user.slComm[4] : user.level == 6 ? user.slComm[5] : user.level == 7 ? user.slComm[6] : user.slComm[6],
                            level: user.level,
                            coins: user.coins,
                            fixLimit: user.fixLimit,
                            liability: user.liability,
                            top: user.top,
                            status: user.status,
                            betStatus: user.betStatus,
                            createdAt: user.createdAt
                        }
                    }),
                    maxUsers: userData.maxUsers
                };
            })
            )
            .subscribe((transformedUserData) => {
                this.users = transformedUserData.users;
                this.usersUpdated.next({
                    users: [...this.users],
                    totalCount: transformedUserData.maxUsers
                });
            });
    }

    getUpdateUsersListner() {
        return this.usersUpdated.asObservable();
    }

    changePassword(userId: any, password: String, confirmPassword: String) {
        const userData = { userId, password, confirmPassword };
        return this.http.post(BACKEND_URL + "/changePassword/", userData);
    }

    changeName(userId: any, firstName: String) {
        const userData = { userId, firstName };
        return this.http.post(BACKEND_URL + "/changeName/", userData);
    }

    getClients() {
        this.http.get<{ message: string, result: any, maxUsers: number }>(BACKEND_URL + "/getClients")

            .subscribe((client) => {
                this.clients = client.result;
                this.clientsUpdated.next({
                    clients: [...this.clients],
                    totalCount: client.maxUsers
                });
            });
    }

    getUpdateClientsListner() {
        return this.clientsUpdated.asObservable();
    }

    getOtpReport(user) {
        const query = `?search=${user}`;
        this.http.get<{ message: string, result: any, status: string }>(BACKEND_URL + "/getInfo" + query).subscribe((data) => {
            this.otp = data.result;
            this.otpUpdated.next({
                otp: [...this.otp]
            });
        }, error => {
            this.otp = [];
            this.otpUpdated.next({
                otp: [...this.otp]
            });
        });
    }

    getUpdateOtpReportListner() {
        return this.otpUpdated.asObservable();
    }

    getEventLenden(eventId) {
        this.http.get<{ message: string, result: any, status: string }>(BACKEND_URL + "/getEventLendenReport/" + eventId).subscribe((data) => {
            this.lendenE = data.result;
            this.lendenEUpdated.next({
                lendenE: [...this.lendenE],
            });
        });;
    }

    getUpdateEventLendenListner() {
        return this.lendenEUpdated.asObservable();
    }

    getLenden() {
        this.http.get<{ message: string, result: any, status: string }>(BACKEND_URL + "/getLendenReport").subscribe((data) => {
            this.lenden = data.result;
            this.lendenUpdated.next({
                lenden: [...this.lenden],
            });
        });;
    }

    getUpdateLendenListner() {
        return this.lendenUpdated.asObservable();
    }

    getDirectUsers(userId: any, type: any) {
        this.http.post<{ message: string, result: any, maxUsers: number }>(BACKEND_URL + "/getDirectUsers", { userId, type })
            .subscribe((data) => {
                this.usersD = data.result;
                this.usersDUpdated.next({
                    usersD: [...this.usersD]
                });
            });
    }

    getUpdateDirectUsersListner() {
        return this.usersDUpdated.asObservable();
    }

    changeOPassword(oldPassword: String, newPassword: String, confirmPassword: String) {
        const userData = { oldPassword, newPassword, confirmPassword };
        //console.log(userData);
        return this.http.post(BACKEND_URL + "/changeOPassword/", userData);
    }

    setMessage(description, type) {
        const userData = { description };
        if (type == 'client') {
            return this.http.post(BACKEND_URL + "/setClientMessage", userData);
        }
        else {
            return this.http.post(BACKEND_URL + "/setDownlineMessage", userData);
        }
    }

    getMessage(type) {
        this.http.get<{ message: string, result: any, status: string }>(BACKEND_URL + "/getMessage/" + type).subscribe((data) => {
            this.message = data.result;
            this.messageUpdated.next({
                message: { ...this.message },
            });
        });;
    }

    getUpdateMessageListner() {
        return this.messageUpdated.asObservable();
    }

    setCashTxn(body) {
        return this.http.post(BACKEND_URL + "/setCashTxn", body);
    }

    setLimit(body) {
        return this.http.post(BACKEND_URL + "/setLimit", body);
    }

    setCLimit(body) {
        return this.http.post(BACKEND_URL + "/setCLimit", body);
    }

    getDirectDL() {
        this.http.post<{ message: string, result: any, current: any, fixLimit: number }>(BACKEND_URL + "/getDirectDL", {})
            .subscribe((data) => {
                this.usersDL = data.result;
                this.usersDLUpdated.next({
                    usersDL: [...this.usersDL],
                    current: data.current,
                    fixLimit: data.fixLimit
                });
            });
    }

    getUpdateDirectDLListner() {
        return this.usersDLUpdated.asObservable();
    }

    getDLReport(eventId) {
        this.http.post<{ message: string, result: any }>(BACKEND_URL + "/getMyDL", { eventId })
            .subscribe((data) => {
                this.dLReport = data.result;
                this.dLReportUpdated.next({
                    dLReport: [...this.dLReport],
                });
            });
    }

    getUpdateDLReportListner() {
        return this.dLReportUpdated.asObservable();
    }

    depositSelfCoins(coins) {
        const userData = { coins };
        return this.http.post(BACKEND_URL + "/depositSelfCoins", userData);
    }

    setSetting(status) {
        return this.http.post(BACKEND_URL + "/setSetting", { status });
    }

    getSetting() {
        this.http.get<{ message: string, result: any, status: string }>(BACKEND_URL + "/getSetting").subscribe((data) => {
            this.setting = data.result;
            this.settingUpdated.next({
                setting: { ...this.setting },
            });
        });;
    }

    getUpdateSettingListner() {
        return this.settingUpdated.asObservable();
    }

    getCoinHistory(userId, start = '', end = '', type = '') {
        const query = `?start=${start}&end=${end}&type=${type}`;
        this.http.get<{ message: string, result: any, status: string }>(BACKEND_URL + "/getCHistory/" + userId + query).subscribe((data) => {
            this.cHistory = data.result;
            this.cHUpdated.next({
                cHistory: [...this.cHistory],
            });
        });;
    }

    getUpdateCoinHistoryListner() {
        return this.cHUpdated.asObservable();
    }

    getInactiveUsers(limit: number, currentPage: number, search: string) {
        const queryParams = `?pagesize=${limit}&page=${currentPage}&search=${search}`;
        this.http.get<{ message: string, result: any, maxPosts: number }>(BACKEND_URL + "/getInactiveUsers" + queryParams)
            .subscribe((data) => {
                this.inActiveUsers = data.result;
                this.inActiveUsersUpdated.next({
                    inActiveUsers: [...this.inActiveUsers],
                    totalCount: data.maxPosts
                });
            });
    }

    getUpdateInactiveUsersListner() {
        return this.inActiveUsersUpdated.asObservable();
    }


    updateUserStatus(userId) {
        return this.http.post(BACKEND_URL + "/changeUserStatus", { userId });
    }

    getDirectAgent() {
        this.http.post<{ message: string, result: any, status: any }>(BACKEND_URL + "/getDirectAgent", {})
            .subscribe((data) => {
                this.usersA = data.result;
                this.usersAUpdated.next({
                    usersA: [...this.usersA]
                });
            });
    }

    getUpdateDirectAgentListner() {
        return this.usersAUpdated.asObservable();
    }

    getDirectAC(id: any) {
        this.http.post<{ message: string, result: any, maxUsers: number }>(BACKEND_URL + "/getDirectAC", { id })
            .subscribe((data) => {
                this.usersDAC = data.result;
                this.usersDACUpdated.next({
                    usersDAC: [...this.usersDAC]
                });
            });
    }

    getUpdateDirectACListner() {
        return this.usersDACUpdated.asObservable();
    }

    getJDLReport(eventId) {
        this.http.post<{ message: string, result: any }>(BACKEND_URL + "/getMyJDL", { eventId })
            .subscribe((data) => {
                this.dLJReport = data.result;
                this.dLJReportUpdated.next({
                    dLJReport: [...this.dLJReport],
                });
            });
    }

    getUpdateJDLReportListner() {
        return this.dLJReportUpdated.asObservable();
    }

    checkuser(body: any) {
        return this.http.post(BACKEND_URL + "/check-user-name", body);
      }

      getMyLimit(body){
        return this.http.post(BACKEND_URL + "/getMyLimit", body);
      }

      getCoinHistoryModal(userId, start = '', end = '', type = '') {
        const query = `?start=${start}&end=${end}&type=${type}`;
       return this.http.get(BACKEND_URL + "/getCHistory/" + userId + query);
    }

    getMyExpo(body){
        return this.http.post(BACKEND_URL + "/getMyExpo", body);
    }
}