import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { io } from 'socket.io-client';
import { Observable, Observer, Subject } from 'rxjs';
import { AuthService } from './auth.service';

const SOCKET_URL = environment.socketUrl;
const SOCKET_URL_2 = environment.socketUrl2;


@Injectable({ providedIn: "root" })
export class SocketService {
    private socket: any;
    private socket2: any;
    private socket3: any;
    public odds: any;
    public fancy: any;
    public score: any;
    public bookM: any;
    public khado: any;
    public message: any;
    public userL: any;
    public oddsBook: any;
    public bmBook: any;
    public fancyBook: any;
    public tossBook: any;
    public token: String;
    public casino: any[];
    public casinoResult: any[];
    public casinoBook: any;
    public khadoBook: any;
    public jantriBook: any;
    private oddsUpdated: Observer<{ odds: any, eventId: String }>;
    private fancyUpdated: Observer<{ fancy: any, eventId: String }>;
    private scoreUpdated: Observer<{ score: any, eventId: String }>;
    private bookMUpdated: Observer<{ bookM: any, eventId: String }>;
    private khadoUpdated: Observer<{ khado: any, eventId: String }>;
    private messageUpdated: Observer<{ message: any }>;
    private userLUpdated: Observer<{ userL: any }>;
    private oddsBookUpdated: Observer<{ oddsBook: any }>;
    private bmBookUpdated: Observer<{ bmBook: any }>;
    private fancyBookUpdated: Observer<{ fancyBook: any }>;
    private tossBookUpdated: Observer<{ tossBook: any }>;
    private khadoBookUpdated: Observer<{ khadoBook: any }>;
    private casinoUpdated: Observer<{ casino: any }>;
    private casinoResultUpdated: Observer<{ casinoResult: any }>;
    private casinoBookUpdated: Observer<{ casinoBook: any }>;
    private jantriBookUpdated: Observer<{ jantriBook: any }>;
    private oddsBookUpdated2 = new Subject();
    constructor(private authService: AuthService) {
    }

    public connect = () => {
        this.token = this.authService.getToken();
        this.socket = io(SOCKET_URL, {
            transports: ['websocket'],
            forceNew: true,
            query: {
                "token": `${this.token}`
            }
        });
    }

    public oConnect = () => {
        this.socket2 = io(SOCKET_URL_2, {
            transports: ['websocket'],
            forceNew: true,
        });

        // this.socket3 = io("http://139.59.82.99:3000", {
        //     transports: ['websocket'],
        //     forceNew: true,
        // });
    }

    public setOdds = (eventId) => {
        this.socket2.emit('Market', eventId);
    }

    public getOdds = (eventId) => {
        this.socket2.on('Market/' + eventId, (odds) => {
            //console.log(odds);
            this.odds = odds;
            this.oddsUpdated.next({
                odds: this.odds,
                eventId
            });
        });
    }

    public getUpdateOddsListner(): Observable<any> {
        return new Observable(observer => {
            this.oddsUpdated = observer;
        });
    }

    public setFancy = (eventId) => {
        this.socket2.emit('ASession', eventId);
    }

    public getFancy = (eventId) => {
        this.socket2.on('ASession/' + eventId, (fancy) => {
            //console.log(data);
            this.fancy = fancy;
            this.fancyUpdated.next({
                fancy: this.fancy,
                eventId
            });
        });
    }

    public getUpdateFancyListner(): Observable<any> {
        return new Observable(observer => {
            this.fancyUpdated = observer;
        });
    }

    // public setBookM = (eventId) => {
    //     this.socket.emit('BookM/Auto', eventId);
    // }

    // public getBookM = (eventId) => {
    //     this.socket.on('BookM/Auto/' + eventId, (bookM) => {
    //         //console.log(data);
    //         this.bookM = bookM;
    //         this.bookMUpdated.next({
    //             bookM: this.bookM,
    //             eventId
    //         });
    //     });
    // }

    // public getUpdateBookMListner(): Observable<any> {
    //     return new Observable(observer => {
    //         this.bookMUpdated = observer;
    //     });
    // }

    public setScore = (eventId) => {
        this.socket2.emit('Score', eventId);
    }

    public getScore = (eventId) => {
        this.socket2.on('Score/' + eventId, (score) => {
            //console.log(odds);
            this.score = score;
            this.scoreUpdated.next({
                score: this.score,
                eventId
            });
        });
    }

    getUpdateScoreListner(): Observable<any> {
        return new Observable(observer => {
            this.scoreUpdated = observer;
        });
    }

    public setMessage = () => {
        this.socket.emit('Message', 'downline');
    }

    public getMessage = () => {
        this.socket.on('Message', (data) => {
            this.message = data.description;
            this.messageUpdated.next({
                message: this.message
            });
        });
    }

    public getUpdateMessageListner(): Observable<any> {
        return new Observable(observer => {
            this.messageUpdated = observer;
        });
    }

    public setUserL = () => {
        this.socket.emit('User', '');
    }

    public getUserL = () => {
        this.socket.on('UserL', (data) => {
            this.userL = data;
            this.userLUpdated.next({
                userL: this.userL
            });
        });
    }

    public getUpdateLUserListner(): Observable<any> {
        return new Observable(observer => {
            this.userLUpdated = observer;
        });
    }

    public setOddsBook = (eventId) => {
        this.socket.emit('Odds/Book', eventId);
    }

    public getOddsBook = (eventId) => {
        this.socket.on('Odds/Book/' + eventId, (book) => {
            this.oddsBook = book;
            this.oddsBookUpdated.next({
                oddsBook: this.oddsBook
            });
        });
    }

    public getUpdateOddsBookListner(): Observable<any> {
        return new Observable(observer => {
            this.oddsBookUpdated = observer;
        });
    }

    public getBmBook = (eventId) => {
        this.socket.on('BM/Book/' + eventId, (book) => {
            this.bmBook = book;
            this.bmBookUpdated.next({
                bmBook: this.bmBook
            });
        });
    }

    public getUpdateBmBookListner(): Observable<any> {
        return new Observable(observer => {
            this.bmBookUpdated = observer;
        });
    }

    public setFancyBook = (eventId) => {
        this.socket.emit('Fancy/Book', eventId);
    }

    public getFancyBook = (eventId) => {
        this.socket.on('Fancy/Book/' + eventId, (book) => {
            //console.log(book);
            this.fancyBook = book;
            this.fancyBookUpdated.next({
                fancyBook: this.fancyBook
            });
        });
    }

    public getUpdateFancyBookListner(): Observable<any> {
        return new Observable(observer => {
            this.fancyBookUpdated = observer;
        });
    }

    public setTossBook = (eventId) => {
        this.socket.emit('Toss/Book', eventId);
    }

    public getTossBook = (eventId) => {
        this.socket.on('Toss/Book/' + eventId, (book) => {
            this.tossBook = book;
            this.tossBookUpdated.next({
                tossBook: this.tossBook
            });
        });
    }

    public getUpdateTossBookListner(): Observable<any> {
        return new Observable(observer => {
            this.tossBookUpdated = observer;
        });
    }

    public setKhado = (eventId) => {
        this.socket.emit('Khado', eventId);
    }

    public getKhado = (eventId) => {
        this.socket.on('Khado/' + eventId, (khado) => {
            //console.log(khado, eventId);
            this.khado = khado;
            this.khadoUpdated.next({
                khado: this.khado,
                eventId
            });
        });
    }

    public getUpdateKhadoListner(): Observable<any> {
        return new Observable(observer => {
            this.khadoUpdated = observer;
        });
    }

    public setKhadoBook = (eventId) => {
        this.socket.emit('Khado/Book', eventId);
    }

    public getKhadoBook = (eventId) => {
        this.socket.on('Khado/Book/' + eventId, (book) => {
            //console.log(book);
            this.khadoBook = book;
            this.khadoBookUpdated.next({
                khadoBook: this.khadoBook
            });
        });
    }

    public getUpdateKhadoBookListner(): Observable<any> {
        return new Observable(observer => {
            this.khadoBookUpdated = observer;
        });
    }

    public setCasino = (eventId) => {
        this.socket2.emit('Casino', eventId);
        this.socket2.emit('CasinoResult', eventId);
    }

    public getCasino = (eventId) => {
        this.socket2.on('Casino/' + eventId, (casino) => {
            this.casino = casino;
            // console.log(casino);

            this.casinoUpdated.next({
                casino: this.casino
            });
        });

        this.socket2.on('CasinoResult/' + eventId, (casino) => {
            this.casinoResult = casino;
            // console.log('casino', casino);

            this.casinoResultUpdated.next({
                casinoResult: this.casinoResult
            });
        });
    }

    getUpdateCasinoListner(): Observable<any> {
        return new Observable(observer => {
            this.casinoUpdated = observer;
        });
    }

    getUpdateCasinoResultListner(): Observable<any> {
        return new Observable(observer => {
            this.casinoResultUpdated = observer;
        });
    }

    public setCasinoBook = (eventId) => {
        this.socket.emit('Casino/Book', eventId);
    }

    public getCasinoBook = (eventId) => {
        this.socket.on('Casino/Book/' + eventId, (book) => {
            //console.log(book);
            this.casinoBook = book;
            this.casinoBookUpdated.next({
                casinoBook: this.casinoBook
            });
        });
    }

    public getUpdateCasinoBookListner(): Observable<any> {
        return new Observable(observer => {
            this.casinoBookUpdated = observer;
        });
    }

    public setJantriBook = (eventId) => {
        this.socket.emit('Jantri/Book', eventId);
    }

    public getJantriBook = (eventId) => {
        this.socket.on('Jantri/Book/' + eventId, (book) => {
            this.jantriBook = book;
            this.jantriBookUpdated.next({
                jantriBook: this.jantriBook
            });
        });
    }

    public getUpdateJantriBookListner(): Observable<any> {
        return new Observable(observer => {
            this.jantriBookUpdated = observer;
        });
    }

    public destoryCricketSocket = (eventId) => {
        //this.socket.off('Market/' + eventId);
        //this.socket.off('Score/' + eventId);
        this.socket.off('Odds/Book/' + eventId);
        this.socket.off('Toss/Book/' + eventId);
        this.socket.off('Fancy/Book/' + eventId);
        this.socket.emit('destroy_admin_cricket_room', eventId);
    }

    public destoryCasinoSocket = (eventId) => {
        this.socket.off('Casino/Book/' + eventId);
        this.socket.emit('destroy_admin_casino_room', eventId);
    }

    public destoryJantriSocket = (eventId) => {
        this.socket.off('Jantri/Book/' + eventId);
        this.socket.emit('destroy_admin_jantri_room', eventId);
    }

    public disconnect = () => {
        this.socket && this.socket.disconnect();
    }

    public disconnect2 = () => {
        this.socket2 && this.socket2.disconnect();
        //this.socket3.disconnect();
    }


    // my code 

    public getOddsBook2 = (eventId) => {
        console.log('aa');
        this.socket.emit('Odds/Book', eventId);
        this.socket.on('Odds/Book/' + eventId, (book: any) => {
            // this.oddsBook = book;
            console.log('lolodfgh', book);

            // this.oddsBookUpdated2.next({
            //     oddsBook: this.oddsBook
            // });
        });
    }

    public getUpdateOddsBookListner2(): Observable<any> {
        return this.oddsBookUpdated2.asObservable();
    }
}