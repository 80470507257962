<div class="wrapper">
    <!-- Preloader -->
    <!-- <div class="preloader flex-column justify-content-center align-items-center">
        <img class="animation__shake" src="assets/dist/img/AdminLTELogo.png" alt="AdminLTELogo" height="60" width="60">
    </div> -->

    <app-header></app-header>
    <app-sidebar></app-sidebar>
    <div class="content-wrapper pb-5">
        <!-- Content Header (Page header) -->
        <router-outlet></router-outlet>
    </div>
    <footer class="main-footer">
        <strong>Copyright &copy; 2024-2025 <a>GURU7777.NET</a>.</strong>
        All rights reserved.
        <div class="float-right d-none d-sm-inline-block">
            <b>Version</b> 0.0.1
        </div>
    </footer>
</div>