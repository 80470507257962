import { Component, OnDestroy, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subscription } from 'rxjs';
import { AuthService } from 'src/app/service/auth.service';
import { SocketService } from 'src/app/service/socket.service';
import { UserService } from 'src/app/service/user.service';
import swal from "sweetalert2";
declare var $: any;

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css']
})
export class SidebarComponent implements OnInit, OnDestroy {
  name: any;
  level: any;
  message: String;
  coins: string = '';
  pattern = "^\\d+$";
  user: any;
  private messageSubs: Subscription;
  private userSubs: Subscription;
  
  constructor(private authService: AuthService, private socketService: SocketService,
    private userService: UserService, private spinner: NgxSpinnerService) { }

  ngOnInit(): void {
    //$('[data-widget="treeview"]').Treeview('init');
    // this.name = this.authService.getName();
    this.name = this.authService.getUserName()
    this.level = this.authService.getUserLevel();
    setTimeout(() => {
      this.socketService.setMessage();
      this.socketService.getMessage();
      //this.socketService.setUserL();
      //this.socketService.getUserL();
      this.messageSubs = this.socketService.getUpdateMessageListner().subscribe(data => {
        this.message = data.message;
      });
    }, 100);

    this.userService.getUserById();
    this.userSubs = this.userService.getUpdateUserListner().subscribe((data) => {
      this.user = data;
      this.spinner.hide();
    }, error => {
      this.spinner.hide();
    });
  }

  depositSelfCoins() {
    this.spinner.show();
    if (this.coins == '') {
      this.spinner.hide();
      this.msgPopup('Please fill this field', 'error');
      return;
    }

    this.userService.depositSelfCoins(this.coins).subscribe(data => {
      this.coins = '';
      this.spinner.hide();
      document.getElementById("close").click();
      this.userService.getUserById();
      this.msgPopup(data['message'], data['status']);
    }, error => {
      this.spinner.hide();
    });
  }

  msgPopup(title, icon) {
    swal.fire({
      icon: icon,
      title: title,
      showConfirmButton: false,
      timer: 1500
    })
  }


  ngOnDestroy(): void {
    this.userSubs.unsubscribe();
  }

}
