import { HttpInterceptor, HttpRequest, HttpHandler } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthService } from '../../service/auth.service';

@Injectable()
export class UserInterceptor implements HttpInterceptor {
    constructor(private authService: AuthService) { }

    intercept(req: HttpRequest<any>, next: HttpHandler) {
        let authRequest;
        const authToken = this.authService.getToken();
        if (authToken) {
            authRequest = req.clone({
                headers: req.headers.set("Authorization", "Bearer " + authToken)
            });
            return next.handle(authRequest);
        }
        else {
            return next.handle(req);
        }
    }
}